import React from "react";
import "./PrivacyPolicy.css";

export default function PrivacyPolicyEn() {
  return (
    <div className="PrivacyPolicyC">
      <PRS1 />
    </div>
  );
}

function PRS1() {
  return (
    <>
      <div className="PRS2">
        {/*  */}
        <div className="PRS2P1">
          <div className="PRS2P1T1">Privacy & Policy</div>
          <PRBox
            Para={
              <>
                Welcome to <span className="PPSB">XAM!</span> Your privacy is important to us. <br /> This Privacy Policy explains how we collect, use,
                disclose, and safeguard your information when you use the XAM mobile application (the "App"). <br /> By using the App, you agree to the terms
                outlined in this Privacy Policy.
                <br />
                <br />
                <span className="PPSB"> 1. Information We Collect</span>
                <br />
                <br />
                When you use XAM, we may collect the following information:
                <ul>
                  <li>
                    <span className="PPSB">Personal Information:</span> Name, email address, and contact details when you register or communicate with us.
                  </li>
                  <li>
                    <span className="PPSB">Document Data: </span> Expiration dates and types of documents (e.g., ID, passport, birth certificate, club
                    membership) that you voluntarily add to the App.
                  </li>
                  <li>
                    <span className="PPSB">Usage Data:</span> Information about how you interact with the App, such as access times, device type, and operating
                    system.
                  </li>
                  <li>
                    <span className="PPSB">Log Data:</span> Automatically collected data, including IP addresses, app errors, and performance logs, to improve
                    functionality.
                  </li>
                </ul>
                {/*  */}
                <span className="PPSB"> 2. How We Use Your Information</span>
                <br />
                <br />
                We use the collected data to:
                <ul>
                  <li>Provide and maintain the App's functionality</li>
                  <li>Send notifications and reminders regarding document expiration dates.</li>
                  <li>Enhance user experience and improve App features.</li>
                  <li>Respond to inquiries and provide customer support.</li>
                  <li>Respond to inquiries and provide customer support.</li>
                </ul>
                {/*  */}
                <span className="PPSB"> 3. Data Sharing and Disclosure</span>
                <br />
                <br />
                We do <span className="PPSB">not</span> sell, trade, or rent your personal information. However, we may share your data in the following cases:
                <ul>
                  <li>
                    <span className="PPSB">Service Providers:</span> Third-party vendors who assist in App operations (e.g., cloud storage, analytics).
                  </li>
                  <li>
                    <span className="PPSB">Legal Compliance:</span> If required by law, regulation, or government requests.
                  </li>
                  <li>
                    <span className="PPSB">Business Transfers:</span> In the event of a merger, acquisition, or asset sale, your data may be transferred to a
                    new owner.
                  </li>
                </ul>
                {/*  */}
                <span className="PPSB">4. Data Security</span>
                <br />
                <br />
                We implement security measures to protect your information against unauthorized access, alteration, or destruction. However, no method of
                transmission over the internet is 100% secure. We encourage users to take necessary precautions to safeguard their data.
                {/*  */}
                <br />
                <br />
                <span className="PPSB">5. Data Retention</span>
                <br />
                <br />
                We retain your data as long as you use the App. If you delete your account, your personal data will be removed from our active databases, except
                where retention is required by law.
                {/*  */}
                <br />
                <br />
                <span className="PPSB">6. Your Rights</span>
                <br />
                <br />
                Depending on your location, you may have the right to:
                <ul>
                  <li>Access, update, or delete your personal information.</li>
                  <li>Withdraw consent for data processing.</li>
                  <li>Request a copy of your data.</li>
                </ul>
                To exercise these rights, contact us at <span className="PPSB">[Insert Contact Email].</span>
                {/*  */}
                <br />
                <br />
                <span className="PPSB">7. Third-Party Links and Services</span>
                <br />
                <br />
                XAM may contain links to third-party websites or services. We are not responsible for their privacy practices and encourage you to review their
                policies before providing any personal information.
                {/*  */}
                <br />
                <br />
                <span className="PPSB">8. Changes to This Privacy Policy</span>
                <br />
                <br />
                We may update this Privacy Policy from time to time. We will notify users of any significant changes via in-app notifications or email. Your
                continued use of the App after changes are posted constitutes your acceptance of the new policy.
                {/*  */}
                <br />
                <br />
                By using XAM, you agree to the terms of this Privacy Policy. Thank you for trusting us to help manage your document reminders!
              </>
            }
          />
        </div>
      </div>
    </>
  );
}

function PRBox({ Para }) {
  return (
    <>
      <div className="PRBox">
        <div className="PRBoxT2">{Para}</div>
      </div>
    </>
  );
}
