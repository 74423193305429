import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./HomeS5.css";
import HomeS2S1 from "../../../Assets/Images/HomeS2S1.png";
import HomeS2S2 from "../../../Assets/Images/HomeS2S2.png";
import HomeS2S3 from "../../../Assets/Images/HomeS2S3.png";
import HomeS2S4 from "../../../Assets/Images/HomeS2S4.png";
import HomeS2S5 from "../../../Assets/Images/HomeS2S5.png";
import HomeS2S6 from "../../../Assets/Images/HomeS2S6.png";
import HomeS2S7 from "../../../Assets/Images/HomeS2S7.png";
import HomeS2S8 from "../../../Assets/Images/HomeS2S8.png";
import ScanYellow from "../../../Assets/Svg/Scan-Yellow.svg";

export default function HomeS5() {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [HomeS2S1, HomeS2S2, HomeS2S3, HomeS2S4, HomeS2S5, HomeS2S6, HomeS2S7, HomeS2S8];
  const altTexts = ["aaa"];
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [images.length]);

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const targetElement = document.querySelector(location.hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <>
      <div id="HS5C" className="HomeS5">
        <div className="HomeS5P2">
          <img src={images[currentImage]} alt={altTexts[currentImage]} className="HomeS5P2Img" />
          <img src={ScanYellow} alt="" className="ScanYellow" />
        </div>
        {/*  */}
        <div className="HomeS5P1">
          {/*  */}
          <div className="HomeS2P1T">
            <div className="HomeS5P1TM">
              About <span>Us</span>
            </div>
            <div className="HomeS5P1TM2">
              Xam, your intelligent digital assistant, automates document organization and renewal tracking, allowing you to focus on more important tasks.
            </div>
            <div className="HomeS5P1TM3">Simplify Your Life,</div>
            <div className="HomeS5P1T1">Time to Snap with a single Tap!</div>
          </div>
          {/*  */}
          <div className="HomeS5P1B">
            <div>We know how essential it is to keep track of important expiration dates, whether it’s for you or your family. </div>
            <div>That’s why we’ve made it our mission to ensure you never miss a critical date, assignment, or official document renewal. </div>{" "}
            <div>With xam, staying on top of your reminders is easier and simpler than ever before. </div>
            <div>Just scan your documents, enjoy a peace of mind and let xam streamline your life.</div>
            <a href="/">Download xam today and experience the future of effortless reminders..</a>
          </div>
          {/*  */}
        </div>
        {/*  */}
      </div>
      {/*  */}
      {/* For Mob */}
      {/*  */}
    </>
  );
}
