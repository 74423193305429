import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Header.css";
import Logo from "../../Assets/Svg/Logo.svg";

export default function EnglishHeader() {
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleLanguageChange = () => {
    const currentPath = location.pathname;
    const isArabic = currentPath.endsWith("/ar");
    const basePath = isArabic ? currentPath.slice(0, -3) : currentPath;
    const newPath = isArabic ? basePath : `${basePath}${basePath.endsWith("/") ? "ar" : "/ar"}`;
    navigate(newPath, { replace: true });
  };

  return (
    <>
      <div className="EnHeaderContainer">
        <div className="EnHeaderL">
          <Link to="/">
            <img src={Logo} alt="Logo" />
          </Link>
        </div>
        <div className="EnHeaderR">
          <div className="EnHeaderRC">
            <div className="EnHeaderRF"></div>
            <Link to="/#HS2C">Features</Link>
          </div>
          <div className="EnHeaderRL"></div>
          <div className="EnHeaderRC">
            <div className="EnHeaderRF"></div>
            <Link to="/#HS5C" className="LinkSpan">
              About <span> Us</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
